export const API_PREFIX = process.env.REACT_APP_API_BASE_URL;
export const MY_STORAGE = window.sessionStorage;

export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";

// Urls
export const URL = {
  LANDING: "/",
  NO_ACCESS: "/403",

  IFRAME_ROI: "/roi-calculator",
};
