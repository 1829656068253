import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./index.scss";
import Routes from "./routes";

import RoiCalculator from "./pages/RoiCalculator";

import reportWebVitals from "./reportWebVitals";

const rootElem = document.getElementById("root");
if (rootElem) {
  const root = ReactDOM.createRoot(rootElem);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </React.StrictMode>
  );
}

const rioElem = document.getElementById("aplRoiCalculator");
if (rioElem) {
  const root = ReactDOM.createRoot(rioElem);
  root.render(
    <React.StrictMode>
      <RoiCalculator />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
