import React, { FC } from "react";

import logo from "./logo.svg";
import "./style.scss";

const Landing: FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>AP Logic Scripts Home Page</p>
      </header>
    </div>
  );
};

export default Landing;
