import React, { FC } from "react";
import { useRoutes } from "react-router-dom";

import NotFound from "./pages/NotFound";
import Landing from "./pages/Landing";

import RoiCalculator from "./pages/RoiCalculator";

import { URL } from "./utils/constants";

const MyRoutes: FC = () => {
  return useRoutes([
    { path: "/", element: <Landing /> },
    { path: URL.IFRAME_ROI, element: <RoiCalculator /> },
    { path: "*", element: <NotFound /> },
  ]);
};

export default MyRoutes;
